import '../styles/index.sass'
import type { AppProps } from 'next/app'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import '@fortawesome/fontawesome-svg-core/styles.css'
import mixpanel from 'mixpanel-browser'
import { useEffect } from 'react'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { library, config } = require('@fortawesome/fontawesome-svg-core')

mixpanel.init('be2a18ec42aa73d88805cdd91fd38d77', {
  debug: true,
  api_host: 'https://api-eu.mixpanel.com',
})

config.autoAddCss = false

library.add(fas, far)

const App = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    mixpanel.track('Loaded Page', {
      page: window.location.pathname,
    })
  }, [])
  return <Component {...pageProps} />
}

App.getInitialProps = async () => {
  try {
    const globalData = await fetch(
      `${process.env.NEXT_INTERNAL_API_URL}api/global?populate=deep,10`
    )
      .then((res) => res.json())
      .catch((e) => {
        console.log('error: failed to fetch global data', e)
      })

    const globalDataAttributes = globalData.data.attributes

    return { pageProps: { global: globalDataAttributes } }
  } catch (error) {
    return {}
  }
}

export default App
